import React from 'react';
import DatePicker from 'react-datepicker';
import update from 'immutability-helper';
import moment from 'moment';
import classNames from 'classnames';

import 'react-datepicker/dist/react-datepicker.css';
import './App.css';

class ProjectStep extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      enabled: true,
      title: '',
      numBusinessDays: 1,
      startDate: null,
    };
  }

  handleChange(field, value) {
    this.setState({[field]: value});
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(field, value);
    }
  }

  getTitle() {
    const dateFormat = 'MMM D, YYYY (ddd)';
    const dateString = this.state.startDate ? this.state.startDate.format(dateFormat) : '';
    const title = this.state.title;
    return <span>{dateString ? <span>{dateString} · {title}</span> : title}</span>;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.enabled !== this.props.enabled) {
      this.setState({enabled: this.props.enabled});
    }
    if (prevProps.title !== this.props.title) {
      this.setState({title: this.props.title});
    }
    if (prevProps.numBusinessDays !== this.props.numBusinessDays) {
      this.setState({numBusinessDays: this.props.numBusinessDays});
    }
    if (prevProps.startDate !== this.props.startDate) {
      this.setState({startDate: this.props.startDate});
    }
  }

  componentDidMount() {
    this.setState({
      enabled: !!this.props.enabled,
      title: this.props.title,
      numBusinessDays: this.props.numBusinessDays,
      startDate: this.props.startDate || null,
    });
  }

  render() {
    const classes = classNames({
      'project-step': true,
      'disabled': !this.state.enabled,
    });
    return (
      <div className={classes}>
        <div>
          <input
            type="checkbox"
            checked={this.state.enabled}
            onChange={e => this.handleChange('enabled', e.target.checked)}
          />
        </div>
        <div>
          <input
            type="number"
            min="1"
            value={this.state.numBusinessDays}
            onChange={e => this.handleChange('numBusinessDays', e.target.value)}
            className="days"
            disabled={!this.state.enabled}
          />
        </div>
        <div>
          <span className="title">{this.getTitle()}</span>
        </div>
      </div>
    );
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.state = {
      startDate: new Date(),
      steps: [
        {
          title: 'Problem Analysis',
          numBusinessDays: 2,
          enabled: true,
        },
        {
          title: 'Competitive Analysis / Research',
          numBusinessDays: 5,
          enabled: true,
        },
        {
          title: 'Conceptualize Logo Ideas',
          numBusinessDays: 14,
          enabled: true,
        },
        {
          title: 'First Digital Draft',
          numBusinessDays: 4,
          enabled: true,
        },
        {
          title: 'Branding Typography and Color Theory - Part 1',
          numBusinessDays: 3,
          enabled: true,
        },
        {
          title: 'Branding Typography and Color Theory - Part 2',
          numBusinessDays: 2,
          enabled: true,
        },
        {
          title: 'Second Digital Draft',
          numBusinessDays: 5,
          enabled: true,
        },
        {
          title: 'Third Digital Draft',
          numBusinessDays: 3,
          enabled: true,
        },
        {
          title: 'Finalized Logo Files',
          numBusinessDays: 5,
          enabled: true,
        },
        {
          title: 'Style Guide',
          numBusinessDays: 7,
          enabled: true,
        },
      ]
    };
  }

  handleStartDateChange(date) {
    this.setState({
      startDate: date,
      steps: this.recomputeSteps(date, [...this.state.steps]),
    });
  }

  handleStepChange(index, field, value) {
    if (field === 'enabled') {
      let steps = update(this.state.steps, {[index]: {enabled: {$set: value}}});
      this.setState({steps: this.recomputeSteps(this.state.startDate, steps)});
    } else if (field === 'numBusinessDays') {
      let steps = update(this.state.steps, {[index]: {numBusinessDays: {$set: value}}});
      this.setState({steps: this.recomputeSteps(this.state.startDate, steps)});
    }
  }

  recomputeSteps(date, steps) {
    date = date ? date : this.state.startDate;
    return steps.map((step) => {
      if (step.enabled) {
        const newDate = this.getFutureDate(date, step.numBusinessDays);
        step.startDate = newDate;
        date = newDate;
      }
      return step;
    });
  }

  getFutureDate(date, businessDays) {
    date = moment(date);
    // iteratively add days not counting weekends
    while (businessDays > 0) {
      date.add(1, 'day');
      businessDays -= date.isoWeekday() <= 5 ? 1 : 0;
    }
    return date;
  }

  componentDidMount() {
    this.setState({steps: this.recomputeSteps(this.state.startDate, this.state.steps)});
  }

  render() {
    return (
      <div className="App">
        <h2>Project Proposal Calculator</h2>

        <DatePicker
          selected={this.state.startDate}
          onChange={date => {this.handleStartDateChange(date)}}
        />

        {this.state.steps.map((step, i) => {
          return (
            <ProjectStep
              key={i}
              startDate={step.startDate}
              numBusinessDays={step.numBusinessDays}
              title={step.title}
              enabled={step.enabled}
              onChange={(type, value) => this.handleStepChange(i, type, value)}
            />
          );
        })}
      </div>
    );
  }
}

export default App;
